import React, { useState, useEffect } from "react";
import OurLayouts from "../layouts";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "../../assets/search.svg";
import { APP_ROUTES } from "../../constants/approutes";

const Reordermedicine = () => {
  const location = useLocation();
  const { order, partnerCode, data } = location.state || {};
  const [quantities, setQuantities] = useState({});
  const [buyQuantities, setBuyQuantities] = useState({});
  const [getFreeQuantities, setGetFreeQuantities] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [medicineList, setMedicineList] = useState([]);
  const [isProceedDisabled, setIsProceedDisabled] = useState(true);
  const navigate = useNavigate();

  const fetchMedicineList = async () => {
    console.log("fcghjbk");
    try {
      const response = await fetch(`${API_URL}order/getOrderItemDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: order.orderId,
          partnerCode: order.partnerCode,
        }),
      });

      const data = await response.json();
      console.log(data, "xdcfvghbj");
      if (data.successList) {
        setMedicineList(data.successList);
        const initialCheckedItems = data.successList.reduce((acc, medicine) => {
          acc[medicine.drugCode] = true; // All medicines checked by default
          return acc;
        }, {});
        setCheckedItems(initialCheckedItems);
        const initialQuantities = data.successList.reduce((acc, medicine) => {
          acc[medicine.drugCode] = medicine.quantity || 0;
          return acc;
        }, {});
        setQuantities(initialQuantities);
      }
    } catch (error) {
      console.error("Error fetching medicine list:", error);
    } finally {
    }
  };
  useEffect(() => {
    fetchMedicineList();
  }, []);
  useEffect(() => {
    const filtered = medicineList.filter(
      (medicine) =>
        medicine.drugName &&
        medicine.drugName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMedicines(filtered);
  }, [medicineList, searchQuery]);

  // Handle Buy and Get Free changes
  const handleBuyChange = (drugCode, value) => {
    // Check if value is an empty string and reset to 0 if needed
    const numericValue = value === "" ? "" : Math.max(0, parseInt(value) || 0);
    setBuyQuantities((prev) => ({
      ...prev,
      [drugCode]: numericValue, // Allow empty string or numeric value
    }));
  };

  // Handle Get Free changes
  const handleGetFreeChange = (drugCode, value) => {
    // Check if value is an empty string and reset to 0 if needed
    const numericValue = value === "" ? "" : Math.max(0, parseInt(value) || 0);
    setGetFreeQuantities((prev) => ({
      ...prev,
      [drugCode]: numericValue, // Allow empty string or numeric value
    }));
  };

  useEffect(() => {
    const hasCheckedItems = Object.values(checkedItems).some((value) => value);
    setIsProceedDisabled(!hasCheckedItems);
  }, [checkedItems]);
  const handleProceed = () => {
    const checkedData = medicineList
      .filter((medicine) => checkedItems[medicine.drugCode])
      .map((medicine) => ({
        id: medicine.drugCode,
        name: medicine.drugName,
        price: medicine.unitPrice,
        quantity: quantities[medicine.drugCode] || 0,
        buy: buyQuantities[medicine.drugCode] || 0,
        getFree: getFreeQuantities[medicine.drugCode] || 0,
        drugCategory: medicine.drugCategory,
      }));
    navigate(APP_ROUTES.MEDICINEORDER, {
      state: {
        checkedData: checkedData,
        passedData: data,
        partnerCode: partnerCode,
      },
    });
  };
  const handleCheckboxChange = (drugCode) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [drugCode]: !prevCheckedItems[drugCode],
    }));
  };
  // const updateQuantity = (drugCode, change) => {
  //   setQuantities((prevQuantities) => {
  //     const newQuantity = Math.max(0, (prevQuantities[drugCode] || 0) + change);
  //     setCheckedItems((prevCheckedItems) => ({
  //       ...prevCheckedItems,
  //       [drugCode]: newQuantity > 0,
  //     }));
  //     return { ...prevQuantities, [drugCode]: newQuantity };
  //   });
  // };

  const updateQuantity = (drugCode, change) => {
    setQuantities((prevQuantities) => {
      // Determine the new quantity based on whether `change` is a number (from buttons) or a string (from input)
      let newQuantity;

      if (typeof change === "number") {
        // Button click - increment or decrement
        newQuantity = Math.max(0, (parseInt(prevQuantities[drugCode] || 0, 10) + change));
      } else if (change === "") {
        // Input field cleared - allow empty string
        newQuantity = "";
      } else {
        // Input field value - parse as integer
        newQuantity = Math.max(0, parseInt(change, 10) || 0);
      }

      // Update checked status based on new quantity
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [drugCode]: newQuantity > 0,
      }));

      return { ...prevQuantities, [drugCode]: newQuantity };
    });
  };
  return (
    <div className="flex flex-col h-screen">
      <OurLayouts>
        <div className="mt-[102px] font-semibold text-lg">
          All Medicine List
        </div>

        <div className="mt-6">
          <div className="flex justify-end mr-[115px] items-center mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search medicine"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-300 rounded-lg py-2 pr-10 pl-4 focus:outline-none focus:ring-2 focus:ring-green-500 w-80"
              />
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <img
                  src={Search}
                  alt="Search Icon"
                  className="h-5 w-5 text-gray-400"
                />
              </div>
            </div>
          </div>

          <table className="min-w-full table-auto bg-white border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-left">Medicine Name</th>
                <th className="border px-4 py-2 text-left">Price (₹)</th>
                <th className="border px-4 py-2 text-left">Qty</th>
                <th className="border px-4 py-2 text-left">Buy</th>
                <th className="border px-4 py-2 text-left">Get Free</th>
              </tr>
            </thead>
            <tbody>
              {filteredMedicines.map((medicine) => (
                <tr key={medicine.drugCode} className="border-t">
                  <td className="border px-4 py-2">
                    <div className="flex items-center space-x-2">
                      <label className="relative flex items-center">
                        <input
                          type="checkbox"
                          checked={checkedItems[medicine.drugCode]}
                          onChange={() =>
                            handleCheckboxChange(medicine.drugCode)
                          }
                          className="appearance-none h-4 w-4 border border-green-600 rounded-sm focus:outline-none checked:bg-green-600"
                        />
                        <span
                          className={`absolute h-4 w-4 flex items-center justify-center ${
                            checkedItems[medicine.drugCode] ? "block" : "hidden"
                          }`}
                        >
                          <svg
                            className="w-3 h-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </span>
                      </label>
                      <span
                        className="font-medium"
                        style={{ fontSize: "15px" }}
                      >
                        {medicine.drugName}
                      </span>
                    </div>
                  </td>
                  <td
                    className="font-medium border px-4 py-2"
                    style={{ fontSize: "15px" }}
                  >
                    {medicine.unitPrice.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                  <div className="flex items-center space-x-2">
                        <button
                          className="px-2 py-1 bg-gray-200 rounded"
                          onClick={() => updateQuantity(medicine.drugCode, -1)}
                        >
                          -
                        </button>
                        {/* <span>{quantities[medicine.drugCode] || 0}</span> */}
                        <input
                          type="number"
                          value={quantities[medicine.drugCode] !== undefined ? quantities[medicine.drugCode] : ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            updateQuantity(medicine.drugCode, value);
                          }}
                          className="w-16 px-2 py-1 text-center bg-gray-200 rounded"
                          onWheel={(e) => e.target.blur()}
                        />


                        <button
                          className="px-2 py-1 bg-gray-200 rounded"
                          onClick={() => updateQuantity(medicine.drugCode, 1)}
                        >
                          +
                        </button>
                      </div>
                  </td>
                  <td className="border px-4 py-2">
                    <input
                      type="number"
                      className="p-1 border border-gray-300 rounded w-16"
                      onWheel={(e) => e.target.blur()}
                      value={
                        buyQuantities[medicine.drugCode] !== undefined
                          ? buyQuantities[medicine.drugCode]
                          : ""
                      }
                      onChange={(e) =>
                        handleBuyChange(medicine.drugCode, e.target.value)
                      }
                    />
                  </td>
                  <td className="border px-4 py-2">
                    <input
                      type="number"
                      className="p-1 border border-gray-300 rounded w-16"
                      onWheel={(e) => e.target.blur()}
                      value={
                        getFreeQuantities[medicine.drugCode] !== undefined
                          ? getFreeQuantities[medicine.drugCode]
                          : ""
                      }
                      onChange={(e) =>
                        handleGetFreeChange(medicine.drugCode, e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center mt-6">
            <button
              className={`bg-[#18406E] w-[50%] mb-7 text-white py-2 px-6 rounded-full cursor-pointer ${
                isProceedDisabled ? "opacity-50" : ""
              }`}
              disabled={isProceedDisabled}
              onClick={handleProceed}
            >
              Proceed
            </button>
          </div>
        </div>
      </OurLayouts>
    </div>
  );
};

export default Reordermedicine;
