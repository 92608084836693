import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import logo from "../../assets/curebaylogooo.png"; // Update the path as per your project structure
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/approutes';

const Header = () => {
  const settings = [
    // { name: "Profile" },
    { name: "Logout" },
  ];

  const [userInfo, setUserInfo] = useState(null); // State to store user info
  const navigate = useNavigate(); 


  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    }
    console.log(storedUserInfo, "storedUserInfo");
  }, []);


  const [anchorElUser, setAnchorElUser] = useState(null);

  const userName = userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : "Guest";
  const userPhone = userInfo ? userInfo.mobile : "Unknown";
  const firstLetter = userInfo ? userInfo.firstName.charAt(0) : "G";


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLogged');
    localStorage.removeItem('userCode');
    localStorage.removeItem('roleCode');
    localStorage.removeItem('encryptedPayload');
    navigate("/login");
  };

  const handleMenuItemClick = (path, name) => {
    handleCloseUserMenu();

    if (name === "Logout") {
      handleLogout(); 
    } else if (name === "Profile") {
 
      console.log("Navigate to Profile");
    }
  };

  return (
    <div className="bg-white w-[87%] rounded-tl-3xl shadow-[0px_8px_18px_0px_rgba(0,0,0,0.078)] p-2 fixed">
      <div className="flex flex-row items-center">
        {/* Logo Section */}
        <div className="flex items-center ml-4">
          <img
            src={logo}
            alt="Logo"
            // onClick={() => navigate(APP_ROUTES.DASHBOARDORDER)}
            // style={{ cursor: 'pointer' }}
          /> {/* Adjust the size as necessary */}
        </div>

        {/* User Section */}
        <div className="flex items-center ml-auto mr-4 mt-4">
          <Box sx={{ flexGrow: 0, marginRight: "16px" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <div
                  className="w-9 h-9 bg-[#276893] text-white flex items-center justify-center rounded-full font-bold text-sm"
                >
                  {firstLetter}
                </div>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={() => handleMenuItemClick(setting.path, setting.name)}
                >
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* User Info */}
          <div className="flex flex-col mr-16">
            <h1 className="font-medium text-[#276893] text-bold">{userName}</h1>
            <h1 className="font-medium font-mono">{userPhone}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
