import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "../../assets/DeleteIcon.svg";

const ViewDetails = ({ order, setActiveTab, setVisibleView, activeTab }) => {
  console.log(order, "orderdaaaaa");
  const [orderItems, setOrderItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [initialQuantities, setInitialQuantities] = useState({});
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderRejected, setOrderRejected] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const pageSize = 50;
  const [totalPages, setTotalPages] = useState(1);

  // Function to get SLA text
  const getSLAText = (sla) => {
    const slaTextMap = {
      1: "0 -12 Hours",
      2: "12-24 Hours",
      3: "24-36 Hours",
      4: "36-48 Hours",
      5: "48-60 Hours",
      6: "60-72 Hours",
      7: "72 Hrs-1 Week",
      8: "Can not be supplied",
    };
    return slaTextMap[sla] || "";
  };

  const fetchOrderItems = async () => {
    try {
      const url =
        activeTab == 2
          ? "order/getCartItemDetails"
          : "order/getOrderItemDetails";
      console.log(url, "urldataaaaa");
      const idKey = activeTab == 2 ? "cartId" : "orderId";
      const idValue = activeTab == 2 ? order.cartId : order.orderId;
      const payload = {
        partnerCode: order.partnerCode,
        [idKey]: idValue,
        pageNo,
        pageSize,
      };
      const response = await fetch(`${API_URL}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(
        data.successObject.discountPercent,
        data.successObject.discountAmount,
        "dxcfgvh"
      );
      if (data.successList) {
        setOrderItems(data.successList);
        setTotalPages(Math.ceil(data.successList.length / pageSize));
        setDiscountPercent(data.successObject.discountPercent);
        setDiscountAmount(data.successObject.discountAmount);
        calculateSubtotal(data.successList);
        initializeQuantities(data.successList);
      } else {
        setError("Failed to fetch order item details.");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred while fetching order item details.");
    }
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  const paginatedItems = orderItems.slice(
    (pageNo - 1) * pageSize,
    pageNo * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPageNo(newPage);
    }
  };

  const initializeQuantities = (items) => {
    const initialQuantitiesMap = {};
    items.forEach((item, index) => {
      initialQuantitiesMap[index] = item.modifiedQuantity;
    });
    setInitialQuantities(initialQuantitiesMap);
  };

  const updateQuantity = (index, newQuantity) => {
    const maxQuantity = initialQuantities[index];

    if (newQuantity < 1 || newQuantity > maxQuantity) return;

    const updatedItems = [...orderItems];
    updatedItems[index].modifiedQuantity = newQuantity;
    updatedItems[index].modifiedTotalAmount =
      updatedItems[index].modifiedPrice * newQuantity;
    setOrderItems(updatedItems);

    calculateSubtotal(updatedItems);
  };

  const calculateSubtotal = (items) => {
    const newSubtotal = items.reduce(
      (sum, item) => sum + item.modifiedTotalAmount,
      0
    );
    setSubtotal(newSubtotal);
  };

  const deleteItem = (currentItemIndex, id) => {
    const updatedItems = orderItems.filter((item) => item.id !== id);
    setOrderItems(updatedItems);
    calculateSubtotal(updatedItems);
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  useEffect(() => {
    calculateTotal(subtotal, discountPercent);
  }, [subtotal, discountPercent]);

  const calculateTotal = (subtotal, discountPercent) => {
    const discountAmount = (subtotal * discountPercent) / 100;
    const total = subtotal - discountAmount;
    setTotalAmount(total);
  };

  // Handle discount input change
  const handleDiscountChange = (e) => {
    const newDiscount = e.target.value;
    if (newDiscount >= 0 && newDiscount <= 100) {
      setDiscountPercent(newDiscount);
      calculateTotal(subtotal, newDiscount); // Calculate total after discount change
    }
  };

  return (
    <div className="w-full flex flex-col h-full">
      <h2 className="text-xl font-bold mb-4">Order Details</h2>
      {error && <p>{error}</p>}
      <div className="flex-grow overflow-y-auto">
        <Table aria-label="added medicines" className="w-full">
          <TableHead className="bg-[#FAFAFA]">
            <TableRow>
              {/* Table Headers */}
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Medicine Name
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Price(₹)
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Quantity
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Free Quantity
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Total Amount(₹)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((medicine, index) => {
              //   const slaText = getSLAText(medicine?.sla);
              //   const quantity = Number(medicine?.modifiedQuantity);
              //   const buy = Number(medicine?.modifiedBuyQuantity) || 1;
              //   const getFree = Number(medicine?.modifiedGetQuantity) || 0;
              //   const quotient = Math.floor(quantity / buy);
              //   const totalQuantity = quotient * getFree + quantity;

              return (
                <TableRow key={index}>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.alternateMedicineName ? (
                      <>
                        <span style={{ color: "black" }}>
                          {medicine.drugName}
                        </span>
                        <br />
                        <span style={{ color: "orange", fontWeight: "normal" }}>
                          (Alternative Medicine:{" "}
                          {medicine.alternateMedicineName})
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "black" }}>
                        {medicine.drugName}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedPrice
                      ? medicine.modifiedPrice
                      : medicine.unitPrice}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedQuantity
                      ? medicine.modifiedQuantity
                      : medicine.quantity}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedFreeQuantity
                      ? medicine.modifiedFreeQuantity
                      : medicine.freeQuantity}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedTotalAmount
                      ? medicine.modifiedTotalAmount
                      : medicine.totalAmount}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mt-4">
        <div className="bg-[#EEF5FD8A] p-4 rounded-lg w-1/3">
          <div className="flex justify-between">
            <span className="text-sm font-medium">Subtotal (₹)</span>
            <span className="text-sm font-medium"> {order?.actualAmount}</span>
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-sm font-medium">Discount (%)</span>
            <p> {order?.discountPercent}</p>
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-sm font-medium">Discount Amount (₹)</span>
            <p>{order?.discountAmount}</p>
          </div>
          <hr className="my-2" />
          <div className="flex justify-between">
            <span className="text-lg font-bold">Total (₹)</span>
            <span className="text-lg font-bold"> {order?.totalAmount}</span>
          </div>

   
          {/* <div className="mt-2 flex justify-between">
            <button
              onClick={() => {
                setVisibleView(false);
              }}
              className="bg-[#fff] text-[#004172] py-2 px-4 rounded-full border border-[#004172]"
            >
              Previous
            </button>
            {activeTab == 2 ? (
              <button
              onClick={(e) => handleEdit(order)}
                className="bg-[#004172] text-white py-2 px-4 rounded-full"
              >
                Edit
              </button>
            ) :  roleCode === "SE" &&
            order?.orderStatus === 2 ? (
                <button
                onClick={(e) => handleorderEdit(order)}
                className="bg-[#004172] text-white py-2 px-4 rounded-full"
              >
                Edit
              </button>
            ):""}
          </div> */}
        </div>
      </div>
      {totalPages > 1 && (
        <div className="flex justify-center items-center mt-4 space-x-2 pb-4">
          <button
            className={`w-6 h-6 flex items-center justify-center rounded-full 
            ${pageNo === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 text-black'}`}
            onClick={() => handlePageChange(pageNo - 1)}
            disabled={pageNo === 1}
          >
            &lt;
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`w-6 h-6 flex items-center justify-center rounded-full 
              ${pageNo === index + 1 ? 'bg-green-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}

          <button
            className={`w-6 h-6 flex items-center justify-center rounded-full 
            ${pageNo === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 text-black'}`}
            onClick={() => handlePageChange(pageNo + 1)}
            disabled={pageNo === totalPages}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewDetails;
