import React, { useState } from 'react';
import { Button, DialogActions, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';


const Popup = ({ handleClose, handleSubmit, setActiveTab }) => {
  const location = useLocation();

  const passedData = location.state?.data;
  const partnerCode = location.state?.partnerCode;

  React.useEffect(() => {
    if (partnerCode) {
      console.log("PartnerCode", partnerCode);
    }
  }, [partnerCode]);

  console.log(partnerCode, "swatiiihjbh")

  const API_URL = process.env.REACT_APP_API_URL;
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const userCode = localStorage.getItem("userCode");


  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();

    setIsDragging(false);

    const uploadedFile = event.dataTransfer.files[0];
    if (uploadedFile && uploadedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFile(uploadedFile);
    } else {
      toast.error("Please upload a valid Excel file (.xlsx)");
    }
  };

  // Handle file selection via input button
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFile(selectedFile);
    } else {
      toast.error("Please upload a valid Excel file (.xlsx)");
    }
  };

  // Handle drag over event (for styling or preventing default behavior)
  const handleDragOver = (event) => {
    event.preventDefault();

    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle upload to backend
  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file to upload.');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("partnerCode",  passedData?.code ? passedData?.code : partnerCode);
    formData.append("userCode", userCode);

    try {
      const response = await fetch(`${API_URL}order/uploadDrugOrders`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (data.responseCode === "200" && data.successObject) {
        toast.success('Bulk Order uploaded and saved in Draft successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Set the active tab to 2 (Draft tab)
        setActiveTab(2);
        localStorage.setItem("activeTab", "2");

        handleClose();
      } else if (data.responseCode === "500") {

        toast.error(data.responseMessage || 'File upload failed due to an error');
      } else {

        toast.error('An unexpected error occurred');
      }
    } catch (error) {
      console.error('File upload failed', error);
      toast.error('File upload failed');
    } finally {
      setUploading(false);
      setFile(null);
    }
  };


  // Trigger file input when clicking the link
  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };


  return (
    <div>
      <div>

      {uploading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <CircularProgress size={60} style={{ color: '#3CBA84' }} />
        </div>
      )}
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          style={{
            border: '2px dashed #ccc',
            padding: '50px',
            marginBottom: '20px',
            textAlign: 'center',
            backgroundColor: isDragging ? '#f0f0f0' : '#fff',
            cursor: 'pointer',
          }}
        >
          {file ? (
            <p>File selected: <span style={{ color: '#3CBA84', fontWeight: 'bold' }}>{file.name}</span></p>
          ) : (
            <p>
              Drag and drop your Excel file here, or{' '}
              <span
                onClick={triggerFileInput}
                style={{
                  color: '#3CBA84',
                  cursor: 'pointer'
                }}
              >
                click here
              </span>{' '}
              to select
            </p>
          )}
        </div>

        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
        />


<button
          className="bg-[#3CBA84] text-white py-2 px-6 rounded-full border border-[#3CBA84] hover:bg-[#34A76D] transition duration-300 focus:outline-none"
          onClick={handleUpload}
          disabled={uploading}
        >
          Upload
        </button>

      </div>

      <DialogActions>
        <Button onClick={handleClose} color="error">
          Close
        </Button>

      </DialogActions>
    </div>
  );
};

export default Popup;
